var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", { staticClass: "d-block f16 text-center mb-4" }, [
        _vm._v(_vm._s(_vm.exam.name)),
      ]),
      _vm.examProp.createDate
        ? _c(
            "span",
            {
              staticClass:
                "f13 grey--text text--darken-1 d-flex flex-row align-items-center justify-content-center mt-2",
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("Date of prescription:")) + " "),
              _c("i", { staticClass: "far fa-calendar-alt ms-2 me-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(
                      _vm.examProp.createDate,
                      "dddd , jD jMMMM jYYYY"
                    )
                  ) +
                  " "
              ),
              _c("i", { staticClass: "far fa-clock ms-2 me-1" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("moment")(_vm.examProp.createDate, "HH:MM")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.showDoctorInfo
        ? _c(
            "v-card",
            {
              staticClass: "d-flex align-items-center my-2",
              attrs: { flat: "" },
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "ms-0 me-2 my-2 rounded-lg",
                  attrs: { size: "96" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      "lazy-src": require("@/assets/images/profile-circle.png"),
                      src:
                        _vm.doctor.pic != null && _vm.doctor.pic != ""
                          ? _vm.doctor.pic
                          : require("@/assets/images/profile.jpg"),
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "ms-4 me-0 d-flex flex-column" }, [
                _c("span", { staticClass: "f14 font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.doctor.firstname + " " + _vm.doctor.lastname) +
                      " "
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "mt-1 f13 primary--text text--darken-1" },
                  [_vm._v(" " + _vm._s(_vm.doctor.clinic.name) + " ")]
                ),
                _c(
                  "span",
                  { staticClass: "mt-1 f13 grey--text text--darken-1" },
                  [_vm._v(" " + _vm._s(_vm.doctor.specialties[0].value) + " ")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("v-img", {
        staticClass: "my-3 mx-auto",
        attrs: {
          width: "50%",
          "max-width": "350",
          src: _vm.exam.introImage,
          "lazy-src": require("@/assets/images/placeholder.png"),
        },
      }),
      _c(
        "div",
        { staticClass: "grey lighten-4 rounded-lg text-dark px-4 py-2 mb-8" },
        [
          _c("span", { staticClass: "f15 fw-bold d-block" }, [
            _vm._v(_vm._s(_vm.$t("Description"))),
          ]),
          _c("p", {
            staticClass: "f14 pa-2 m-0",
            domProps: { innerHTML: _vm._s(_vm.exam.userDescription) },
          }),
        ]
      ),
      _c(
        "v-footer",
        {
          staticClass: "pb-2 pt-0",
          attrs: {
            app: "",
            paddless: "",
            bordered: "",
            color: "white",
            "min-height": "130",
          },
        },
        [
          _c(
            "div",
            { staticClass: "border-top w-100" },
            [
              _c("v-container", { attrs: { fluid: "" } }, [
                _vm.exam.examAssignStatus == "PAID" ||
                _vm.exam.examAssignStatus == "FREE"
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _vm.exam.examAssignStatus == "FREE"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Cost")) +
                                    " : " +
                                    _vm._s(_vm.$t("Free"))
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Cost")) +
                                    " : " +
                                    _vm._s(_vm.$t("Paid"))
                                ),
                              ]
                            ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-lg",
                            attrs: { color: "primary", large: "", block: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleNextPage()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Next page")) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm.exam.examAssignStatus == "PAYMENT_PENDING"
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Cost")) +
                                " : " +
                                _vm._s(
                                  _vm._f("VMask")(
                                    _vm.examProp.eprice || 0,
                                    _vm.currencyMask
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.$t("RIAL"))
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-lg",
                            attrs: { color: "primary", large: "", block: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePay()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Pay")) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm.exam.examAssignStatus == "ANSWERED"
                  ? _c("div", { staticClass: "mt-2" }, [
                      _vm.exam.type == "PUBLIC"
                        ? _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2",
                                },
                                [_vm._v(_vm._s(_vm.$t("Answered")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-lg",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleViewResult()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("View result")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-lg",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: "",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Answered")) + " ")]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }